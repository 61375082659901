import React from "react"
import styled from "styled-components";
import { devices } from "../settings"

const Section = styled.a`
  color: black;
  margin: 0px 0px 20px 0px;
  display: block;
  font-size: 25px;
  font-weight: 400;
  font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;

  &:before {
    content:"";
    display: block;
    width:100%;
    height:50px;
  }

  &.hidden {
    visibility: hidden;
    margin: 0px;

    &:before {
        height:20px;
      }    
  }

  @media ${devices.mobile} {
    margin: 10px;

    &:before {
        content:"";
        display: block;
        width:100%;
        height:30px;
    }
  }
`

const SectionHeader = ({children, ...props}) => {
  return (
    <Section {...props}>
        {children}
    </Section>
  )
}

export default SectionHeader
