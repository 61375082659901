import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faMagic } from "@fortawesome/free-solid-svg-icons"
import { isMobile } from "react-device-detect";
import Helmet from "react-helmet"
import loadable from '@loadable/component'

import Page from "../components/layout/Page"
import PageHeader from "../components/layout/PageHeader"
import HeroHeader from "../components/layout/HeroHeader"
import SEO from "../components/seo"
import GoogleImage from "../components/GoogleImage"
import Container from "../components/layout/Container"

import SignGrid from "../components/SignGrid"
import SignTemplatePreview from "../components/SignTemplatePreview"
import SectionHeader from "../components/SectionHeader"
import WhyWidget from "../components/widgets/WhyWidget"
import ProductsWidget from "../components/widgets/ProductsWidget"
import SignPhotoCarousel from "../components/SignPhotoCarousel"

import { colors, storage, devices, reviews } from "../settings"
import Layout2Columns from "../components/layout/Layout2Columns"

const TrustpilotSection = loadable(() => import('../components/sections/TrustpilotSection'));
const PhotoGrid = loadable(() => import('../components/PhotoGrid'));
const MapWidget = loadable(() => import('../components/widgets/MapWidget'));
const DifferentSection = loadable(() => import('../components/sections/DifferentSection'));

const SignCreatePrompt = styled.button`
    background-color: ${colors.orange} !important;
    color: white;
    font-size: 16px;
    border: 0px;
    padding: 8px 16px;
    border-radius: 4px;
    margin-top: 20px;

    a {
        color: white;
    }
    svg {
        margin: 0px 5px 0px 0px;
    }
`
const SeoText = styled.div`
    margin-top: 50px;
`
const Content3 = styled.div`
  background-image: url(${storage.url}2020/tiles/grey4.jpg);
  padding-top: 25px;
  padding-bottom: 25px;

  @media ${devices.mobile} {
    padding-top: 0px;
  }  
`

export default function LandingPage({ data }) {
    const page = data.landingpages.edges[0].page;
    const signs = data.signs.edges;

    const structuredData = JSON.stringify({
        "@context": "https://schema.org/",
        "@type": "Product",
        "sku": page.name,
        "image": page.name == "store_front_signs" ? 
            [storage.url + "2022/test/carousel/channel-letters-200.jpg"]
        :        
            [storage.url + page.url],
        "name": page.title,
        "description": page.description,
        "brand": {
            "@type": "Brand",
            "name": "Sign Monkey"
        },        
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": reviews.rating,
            "reviewCount": reviews.count
        },         
        "review": {
            "@type": "Review",
            "author": {
                "@type": "Person",
                "name": "JMR Max LLC"
            },
            "datePublished": "2022-03-02",
            "reviewBody": "From one business to another, it was easy to tell right from the start that Sign Monkey is a business where all team members care about what they do and how customer relations are handled. Excellent job, excellent communications, and excellent service! Keep up the good work.",
            "name": "Value purchase",
            "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "5",
                "worstRating": "1"
            }
        }       
    })

    return (
        <>
            <SEO 
                page="landingpage" 
                title={page.title + " Signs"}
            />

            <Helmet>
                <script type="application/ld+json">{structuredData}</script> 
            </Helmet>

            {page.name == "store_front_signs" &&
                <>
                    <HeroHeader 
                        heroSrc="2020/hero/building-storefront.jpg"
                        heroMobileSrc="2020/hero/building-phone.jpg"
                        personSrc="2020/hero/person-storefront.png"
                    />
                    <Container>
                        <WhyWidget />
                    </Container>
                    <SignPhotoCarousel />
                    <br />
                    <ProductsWidget />
                    <Container width="800">
                        <MapWidget />
                    </Container>
                    <br />
                    <br />
                </>
            }            
            <Page 
                breadcrumb={[
                    { url: "/", title: "Home"},
                    { url: "/templates/", title: "Sign Templates"},
                    { url: null, title: page.title + " Signs" }
                ]}
            >

                <Layout2Columns left={page.name == "store_front_signs" ? 100:40} right={page.name == "store_front_signs" ? 0:60}>
                    {page.name != "store_front_signs" &&
                        <div>
                            <GoogleImage root src={page.url} required lazy alt={page.title} responsive width={isMobile ? 355:426} height={isMobile ? 122:147} />
                        </div>
                    }
                    <div>
                        <PageHeader title={page.title + " Sign Design Templates"} subtitle={page.description} />

                        <Link to="/builder/?from=LandingPage">
                            <SignCreatePrompt>
                                <FontAwesomeSvgIcon icon={faMagic} />
                                Design Your Own Sign
                            </SignCreatePrompt>
                        </Link>
                    </div>
                </Layout2Columns>

                <SignGrid>
                    {signs.map(({sign}, index) => (
                        <SignTemplatePreview
                            key={index}
                            sign={sign}
                        />
                    ))}
                    {(signs.length == 0) && 
                        <SignCreatePrompt>
                            <Link to="/builder/?from=LandingPage">
                                <FontAwesomeSvgIcon icon={faMagic} />
                                Design Your Own Sign
                            </Link>
                        </SignCreatePrompt>
                    }
                </SignGrid>

                <br />
                <br />

                <TrustpilotSection />

                <SectionHeader id="media">
                    Pictures
                </SectionHeader>
                <PhotoGrid
                    page={page.name}
                />

                <SeoText>
                    <div dangerouslySetInnerHTML={{ __html: page.seo }} />
                    <div dangerouslySetInnerHTML={{ __html: page.seo2 }} />
                </SeoText>
            </Page>

            {page.name == "store_front_signs" && 
                <Content3>
                    <Container>
                        <DifferentSection />
                    </Container>
                </Content3>
            }            
        </>
    )
}

export const query = graphql`
    query($name: String!) {
        landingpages: allMysqlLandingPages(filter: {name: {eq: $name}}) {
            edges {
                page: node {
                    id
                    name
                    title
                    description
                    url
                    seo
                    seo2
                }
            }
        }
        signs: allMysqlLandingPageSigns(filter: {page: {eq: $name}}) {
            edges {
                sign: node {
                    id: sign_id
                    uid: sign_uid
                    title
                    keywords
                    preview_url
                }
            }
        }

        heroImage: file(
            relativePath: { regex: "/building-storefront/" }
        ) {
            childImageSharp {
            gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                width: 1500
                height: 511
            )
            }
        }
        heroPhoneImage: file(
            relativePath: { regex: "/building-phone/" }
        ) {
            childImageSharp {
            gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                width: 800
                height: 712
            )
            }
        }  
        storeOwnerImage: file(
            relativePath: { regex: "/storefront-owner/" }
        ) {
            childImageSharp {
            gatsbyImageData(
                width: 415
                height: 246
            )
            }
        }          
    }
  `    